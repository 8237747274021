import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { User, STATES, WebUpdate, WebUpdatePayload, ACCOUNT_TYPES } from 'app/auth/user.model';
import {
  UpdateUserRequest,
  VerifyEmailRequest,
  VerifyCodeRequest,
  CompanySearchResult,
  SetCompanyPayload as SetOrganizationPayload,
  Organization,
  Feedback,
  SetOrganizationResult,
  UpdateEmailRequest,
} from './users.model';
import { map } from 'rxjs/operators';
import { Utility } from '@dp/utilities';
import { BasicSuccessResponse, InviteUserResult, ActionRequest, ApprovalStatusResponse, Pagination, PagedItems } from '@dp/types';
import moment from 'moment';
import { AuthService } from 'app/auth/auth.service';
import { StaticDataService } from '@dp/services/static-data.service';
import { SA_Organization } from 'app/super-admin/super-admin.model';
import { UtilsService } from '@dp/services/utils.service';
import { Team, TeamUser } from '..';
import { Params } from '@angular/router';
import { Sort } from '@angular/material/sort';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private getUsersInOrganizationUrl = environment.rootUrl + environment.urls.users_in_organization;
  private inviteUsersUrl = environment.rootUrl + environment.urls.invite_users;
  private removeUserUrl = environment.rootUrl + environment.urls.remove_user;
  private getUserUrl = environment.rootUrl + environment.urls.get_user;
  private editUserUrl = environment.rootUrl + environment.urls.edit_user;
  private changePasswordUrl = environment.rootUrl + environment.urls.change_password;
  private verifyEmailUrl = environment.rootUrl + environment.urls.verify_email;
  private getUserByVerificationCodeUrl = environment.rootUrl + environment.urls.get_user_by_verification_code;
  private changeUserAdminUrl = environment.rootUrl + environment.urls.change_user_admin;
  private changeUserRoleUrl = environment.rootUrl + environment.urls.change_user_role;
  private organizationUrl = environment.rootUrl + environment.urls.organization;
  private metaInfoUrl = environment.rootUrl + environment.urls.meta_info;
  private companySearchUrl = environment.rootUrl + environment.urls.companySearch;
  private setAdminApprovalStatusUrl = environment.rootUrl + environment.urls.setAdminApprovalStatus;
  private setPartnershipApprovalStatusUrl = environment.rootUrl + environment.urls.updatePartnershipApprovalStatus;
  private contactUsUrl = environment.rootUrl + environment.urls.contactUs;
  private webUpdatesUrl = environment.rootUrl + environment.urls.webUpdates;
  private setWebUpdateUrl = environment.rootUrl + environment.urls.setWebUpdate;
  private getSa_OrganizationUrl = environment.rootUrl + environment.urls.getSA_OrganizationsUrl;
  private organizationFlagUrl = environment.rootUrl + environment.urls.orgFlag;
  private putOrganizationAccountLimitUrl = environment.rootUrl + environment.urls.orgAccountLimit;
  private teamsUrl = environment.rootUrl + environment.urls.teams;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private staticDataService: StaticDataService,
    private utils: UtilsService
  ) {}

  public getUsersInOrganization(): Observable<User[]> {
    // due to api issue, this call doesn't return back all users. use _limit to force 200 users returned. 
    // another ticket would be created to fix the api issue.
    return this.http.get<User[]>(`${this.getUsersInOrganizationUrl}?_limit=200`).pipe(
      map((users) => {
        const currentUserId = this.authService.currentUserValue.id;
        users.map((user) => {
          user.fullName = Utility.getFullName(user);
          user.initials = Utility.getInitials(user);
          user.ageOfDays = user.state === STATES.NEW ? moment().diff(moment(user.invitedAt), 'days') : 0;
          user.isCurrentUser = user.id === currentUserId;
          if (user.invitedAt) {
            user.invitedAt = moment(user.invitedAt).format(this.authService.currentUserValue.datePattern);
          }
          user.lastLoginTime = user.lastLoginTime ? this.utils.isoStringToLocalDateTimeString(user.lastLoginTime) : '';
        });
        //todo: remove
        // users = this.appendExtra(users);
        users.sort((user1, user2) => (user1.fullName < user2.fullName ? -1 : 1));

        return users;
      })
    );
  }

  public getUsersInOrg(state: string, pagination?: Pagination) {
    const params = {
      ...(state && { state }),
      ...(pagination && {
        _page: pagination.currentPage,
        _limit: pagination.pageSize,
      }),
    };
    return this.http.get<User[]>(`${this.getUsersInOrganizationUrl}`, { observe: 'response', params }).pipe(
      map((res) => {
        const totalCount = +res.headers.get(environment.HEADER.X_TOTAL_COUNT);
        const users = res.body;
        const currentUserId = this.authService.currentUserValue.id;
        users.map((user) => {
          user.fullName = Utility.getFullName(user);
          user.initials = Utility.getInitials(user);
          user.ageOfDays = user.state === STATES.NEW ? moment().diff(moment(user.invitedAt), 'days') : 0;
          user.isCurrentUser = user.id === currentUserId;
          if (user.invitedAt) {
            user.invitedAt = moment(user.invitedAt).format(this.authService.currentUserValue.datePattern);
          }
          user.lastLoginTime = user.lastLoginTime ? this.utils.isoStringToLocalDateTimeString(user.lastLoginTime) : '';
        });
        users.sort((user1, user2) => (user1.fullName < user2.fullName ? -1 : 1));
        return { totalCount, users };
      })
    );
  }

  public getUserWebUpdates(): Observable<WebUpdate[]> {
    return this.http.get<WebUpdate[]>(this.webUpdatesUrl);
  }
  public setUserWebUpdate(payload: WebUpdatePayload): Observable<BasicSuccessResponse> {
    return this.http.post<BasicSuccessResponse>(this.setWebUpdateUrl, payload);
  }

  public getSA_Organizations(params): Observable<any> {
    const defaultParams = {
      q: '',
      _order: 'desc nulls last',
      _page: 1,
      _limit: 20,
    };
    const paramsMerged = { ...defaultParams, ...params };

    //todo: mockup
    paramsMerged._page++;
    if (!paramsMerged.q) delete paramsMerged.q;

    return this.http.get<any>(this.getSa_OrganizationUrl, { observe: 'response', params: paramsMerged }).pipe(
      map((res) => {
        return {
          total: +res.headers.get(environment.HEADER.X_TOTAL_COUNT),
          organizations: res.body as SA_Organization[],
        };
      })
    );
  }

  public updateOrgAccountLimit(orgId: number, payload: any): Observable<any> {
    switch (payload.accountType) {
      case ACCOUNT_TYPES.BASIC: {
        delete payload['activeContainerLimit'];
        delete payload['activeShipmentGroupLimit'];
        delete payload['trialEndDate'];
        delete payload['activeVesselLimit'];
        delete payload['activeAwbLimit'];
        delete payload['activeOutsourcedLogisticsLimit'];
        break;
      }
      case ACCOUNT_TYPES.ENTERPRISE:
      case ACCOUNT_TYPES.PREMIUM:
      case ACCOUNT_TYPES.PLUS: {
        delete payload['trialEndDate'];
        break;
      }
      default:
        break;
    }

    return this.http.put<any>(this.putOrganizationAccountLimitUrl, {
      orgId,
      ...payload,
    });
  }

  public toggleFlag(orgId: number, turnOn: boolean): Observable<any> {
    if (turnOn) {
      return this.http.put<any>(this.organizationFlagUrl + '/' + orgId, {});
    } else {
      return this.http.delete<any>(this.organizationFlagUrl + '/' + orgId);
    }
  }

  sendFeedback(payload: Feedback): Observable<BasicSuccessResponse> {
    payload = Utility.cleanupData(payload) as Feedback;
    return this.http.post<BasicSuccessResponse>(this.contactUsUrl, payload);
  }

  // appendExtra(users: User[]): User[] {
  //   let extraUsers: User[] = [
  //     {
  //       id: 200000003,
  //       userEmail: 'Cheery2.Woods@demoimporter.com',
  //       firstName: 'Nancy',
  //       middleName: null,
  //       lastName: 'Woods',
  //       phone: '1-206-123-4571',
  //       role: null,
  //       locale: 'en-US',
  //       timezone: 'America/Los_Angeles',
  //       colorPreference: 'LT',
  //       state: STATES.VERIFIED,
  //       isAdmin: false,
  //       department: null,
  //       invitedAt: null,
  //       languages: null,
  //       country: null,
  //       userCode: '4368656572792e576f6f64734064656d6f696d706f727465722e636f6d',
  //     },
  //     {
  //       id: 200000004,
  //       userEmail: 'Mike.Woods@demoimporter.com',
  //       firstName: 'Mike',
  //       middleName: null,
  //       lastName: 'Woods',
  //       phone: '1-206-123-4571',
  //       role: null,
  //       locale: 'en-US',
  //       timezone: 'America/Los_Angeles',
  //       colorPreference: 'LT',
  //       state: STATES.VERIFIED,
  //       isAdmin: false,
  //       department: null,
  //       invitedAt: null,
  //       languages: null,
  //       country: null,
  //       userCode: '4368656572792e576f6f64734064656d6f696d706f727465722e636f6d',
  //     },
  //     {
  //       id: 200000005,
  //       userEmail: 'bill.Woods@demoimporter.com',
  //       firstName: 'Bill',
  //       middleName: null,
  //       lastName: 'Gates',
  //       phone: '1-206-123-4571',
  //       role: null,
  //       locale: 'en-US',
  //       timezone: 'America/Los_Angeles',
  //       colorPreference: 'LT',
  //       state: STATES.VERIFIED,
  //       isAdmin: false,
  //       department: null,
  //       invitedAt: null,
  //       languages: null,
  //       country: null,
  //       userCode: '4368656572792e576f6f64734064656d6f696d706f727465722e636f6d',
  //     },
  //   ];
  //   return [...users, ...extraUsers];
  // }

  public inviteUsers(emails: Object): Observable<InviteUserResult[]> {
    const body = Utility.cleanupData(emails);
    return this.http.post<InviteUserResult[]>(this.inviteUsersUrl, body);
  }

  public removeUser(user: User): Observable<BasicSuccessResponse> {
    let queryString: string = this.removeUserUrl;
    queryString = queryString.replace(':userCode', user.userCode);
    return this.http.put<BasicSuccessResponse>(queryString, {});
  }

  public getUser(userCode: string) {
    const queryString = `${this.getUserUrl}/${userCode}`;
    return this.http.get<User>(queryString);
  }

  //todo: eventually we will deprecate this method and use editUserCleanVersion
  public editUser(newUserState: UpdateUserRequest, userCode: string): Observable<HttpResponse<User>> {
    let queryString = `${this.editUserUrl}`;

    queryString = queryString.replace(':userCode', userCode);
    const defaultValues = {
      department: '',
      middleName: '',
      phone: '',
      role: '',
    };

    newUserState = Utility.cleanupData(newUserState) as UpdateUserRequest;
    newUserState = { ...defaultValues, ...newUserState };

    return this.http.put<User>(queryString, newUserState, {
      headers: { 'Content-Type': 'application/json' },
      observe: 'response',
    });
  }
  public editUserCleanVersion(newUserState: UpdateUserRequest, userCode: string): Observable<User> {
    let queryString = `${this.editUserUrl}`;

    queryString = queryString.replace(':userCode', userCode);

    newUserState = Utility.cleanupData(newUserState) as UpdateUserRequest;
    //TODO: api mock
    // if(environment.environmentType === 'qa') {
    //   return of(this.authService.currentUserValue);
    // }
    //TODO: end

    return this.http.put<User>(queryString, newUserState);
  }

  public editUserSingleProperty(payload: Object, userCode: string): Observable<User> {
    let queryString = `${this.editUserUrl}`;

    queryString = queryString.replace(':userCode', userCode);

    payload = Utility.cleanupData(payload) as UpdateUserRequest;

    return this.http.put<User>(queryString, payload);
  }

  public editUserDebounce(newUserState: UpdateUserRequest, userCode: string): Observable<User> {
    let queryString = `${this.editUserUrl}`;
    queryString = queryString.replace(':userCode', userCode);
    // newUserState = Utility.cleanupData(newUserState) as UpdateUserRequest;
    return this.http.put<User>(queryString, newUserState);
  }

  public updateUser(payload: UpdateUserRequest | UpdateEmailRequest, userCode: string): Observable<HttpResponse<User>> {
    let queryString = `${this.editUserUrl}`;
    queryString = queryString.replace(':userCode', userCode);
    return this.http.put<User>(queryString, Utility.cleanupData(payload), {
      headers: { 'Content-Type': 'application/json' },
      observe: 'response',
    });
  }

  public changePasswordRequest(oldPassword: string, newPassword: string, userCode: string) {
    const body = {
      currentPassword: oldPassword,
      newPassword: newPassword,
    };

    let queryString = this.changePasswordUrl;
    queryString = queryString.replace(':userCode', userCode);

    return this.http.put(queryString, body, { headers: { 'Content-Type': 'application/json' } });
  }

  public verifyEmail(request: VerifyEmailRequest): Observable<User> {
    return this.http.put<User>(this.verifyEmailUrl, request);
  }
  public getUserByVerificationCode(request: VerifyCodeRequest): Observable<User> {
    return this.http.post<User>(this.getUserByVerificationCodeUrl, request);
  }

  public companySearch(): Observable<CompanySearchResult> {
    return this.http.get<CompanySearchResult>(this.companySearchUrl);
  }

  public setOrganization(payload: SetOrganizationPayload): Observable<SetOrganizationResult> {
    payload = Utility.cleanupData(payload);
    return this.http.post<SetOrganizationResult>(this.organizationUrl, payload);
  }

  updateOrganizationMetaInfo(payload: Partial<string[]>): Observable<string[]> {
    return this.http.post<string[]>(this.metaInfoUrl, payload);
  }

  getOrganizationMetaInfo(): Observable<string[]> {
    return this.http.get<string[]>(this.metaInfoUrl).pipe(
      map((metaInfo) => {
        // Ensure the array has exactly 20 elements
        while (metaInfo.length < 20) {
          metaInfo.push('');
        }
        return metaInfo;
      })
    );
  }

  updateOrganization(payload: Partial<Organization>): Observable<Organization> {
    //let payloadClean = Utility.cleanupData(payload);
    const orgDefault: Partial<Organization> = {
      organizationName: '',
      industry: '',
      phone: '',
      email: '',
      streetLine1: '',
      streetLine2: '',
      city: '',
      stateProv: '',
      postalCode: '',
      countryCode: '',
    };

    const org = { ...orgDefault, ...Utility.cleanupData(payload) };
    return this.http.put<Organization>(this.organizationUrl, org);
  }

  public changeAdmin(isAdmin: boolean, userCode: string): Observable<BasicSuccessResponse> {
    const url = this.changeUserAdminUrl.replace(':userCode', userCode);
    return this.http.put<BasicSuccessResponse>(url, { isAdmin });
  }

  public changeUserRole(role: string, userCode: string): Observable<BasicSuccessResponse> {
    const url = this.changeUserRoleUrl.replace(':userCode', userCode).replace(':roleName', role);
    return this.http.post<BasicSuccessResponse>(url, null);
  }

  getUserCountryCode(): Observable<Object> {
    return this.http.get<Object>('http://ip-api.com/json');
  }

  public setApprovalStatus(request: ActionRequest): Observable<ApprovalStatusResponse> {
    if (request.approvalRequest === 'ORG_REQ') {
      return this.http.put<ApprovalStatusResponse>(this.setAdminApprovalStatusUrl, request.payload);
    } else if (request.approvalRequest === 'PARTNERSHIP_REQ') {
      return this.http.put<ApprovalStatusResponse>(this.setPartnershipApprovalStatusUrl, request.payload);
    }
  }

  private FilesUploadUrl = environment.rootUrl + environment.urls.files_upload;
  uploadLogoFile(file: FormData): Observable<any> {
    //mock
    return of({ url: 'http://www.car-brand-names.com/wp-content/uploads/2015/05/Tesla-Motors-logo-346x500.png' });
    // return of({ url: 'https://www.honda.com/-/media/Honda-Homepage/Images/Logos/svg/Honda-Power-Of-Dreams-New.svg' });

    //return this.http.post(this.FilesUploadUrl, file);
  }

  // https://dev.azure.com/dpwhotfsonline/DTLP/_wiki/wikis/DTLP.wiki/215/Organizations?anchor=organization-datachain-enable%2Fdisable.
  // Organization Datachain Enable/Disable
  toggleDatachain(payload): Observable<any> {
    return this.http.post<any>(this.organizationUrl + '/blockchain/toggle', payload);
  }

  // toggle include documents preference
  toggleIncludeDocuments(payload): Observable<any> {
    return this.http.put<any>(this.organizationUrl, payload);
  }

  getTeams(pagination: Pagination, sort: Sort): Observable<PagedItems<Team>> {
    const params: Params = {
      _page: pagination.currentPage + 1,
      _limit: pagination.pageSize,
      _order: sort.direction ? sort.direction + ' nulls last' : '',
      _sort: sort.active,
    };
    return this.http.get<Team[]>(this.teamsUrl, { observe: 'response', params }).pipe(
      map((res) => {
        const items = res.body as Array<Team>;
        items.forEach((t) => {
          t.totalUsers = +t.totalUsers;
        });

        return {
          total: +res.headers.get(environment.HEADER.X_TOTAL_COUNT),
          items,
        };
      })
    );
  }
  // url: admin/teams
  getTeamsInOrganization(): Observable<string[]> {
    const url = environment.rootUrl + 'teams/list';
    return this.http.get<string[]>(url);
  }
  getTeamUsers(id: number): Observable<TeamUser[]> {
    const url = `${this.teamsUrl}/${id}/members`;
    return this.http.get<TeamUser[]>(url).pipe(
      map((users) => {
        users.forEach((u) => {
          u.fullName = Utility.getFullName(u);
        });
        return users;
      })
    );
  }
  createTeam(payload: Team): Observable<Team> {
    return this.http.post<Team>(this.teamsUrl, payload);
  }
  updateTeam(id: number, payload: Team): Observable<Team> {
    return this.http.put<Team>(`${this.teamsUrl}/${id}/edit`, payload);
  }
  deleteTeam(id: number): Observable<BasicSuccessResponse> {
    return this.http.delete<BasicSuccessResponse>(`${this.teamsUrl}/${id}`);
  }
  deleteTeamUser(teamId: number, userId: number): Observable<BasicSuccessResponse> {
    return this.http.delete<BasicSuccessResponse>(this.teamsUrl + '/' + teamId + '/members/' + userId);
  }
  addTeamUsers(teamId: number, userEmails: string[]): Observable<BasicSuccessResponse> {
    return this.http.post<BasicSuccessResponse>(this.teamsUrl + '/members', {
      userEmails,
      teamId,
    });
  }
}
